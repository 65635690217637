import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';
import { makeObject } from '@/components/editor/utils/editor';
import { entitiesOptions } from '@/components/editor/config/entitiesOptions';

export interface IEntity {
    id?: string;
    options: {
        name: string;
    };
}

type IPrefetchedEntities = {
    [key: string]: IEntity[];
};

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Entities extends VuexModule {
    entities: IEntity[] = [];
    entity: IEntity | null = null;
    name: string = '';
    options: null;

    prefetchedEntities: IPrefetchedEntities = {};

    @Mutation
    setEntities(entities) {
        this.entities = entities;
    }

    @Mutation
    setPrefetchedEntities({ data, name }) {
        this.prefetchedEntities[name] = data;
    }

    @Mutation
    setEntity(entity) {
        this.entity = entity;
    }

    @Mutation
    setOptions(options) {
        this.options = options;
    }

    @Mutation
    setName(name) {
        this.name = name;
    }

    @Action
    async fetchOptions(name) {
        this.context.commit('setOptions', entitiesOptions[name || this.name]);
    }

    @Action
    async fetchEntity(name) {
        let options = makeObject(entitiesOptions[name || this.name]);
        this.context.commit('setEntity', {
            options: options
        });
    }

    @Action
    async fetchAll(name) {
        try {
            const response = await api.get(`/entities/${name || this.name}`);
            this.context.commit('setEntities', response.data);
            this.context.commit('setPrefetchedEntities', {
                data: response.data,
                name: name || this.name
            });
            return response.data;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async select(payload) {
        try {
            const response = await api.post(`/entities/${payload.name}/select`, {
                conditions: payload.conditions
            });
            return response.data;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async fetchOne({ id, name }) {
        try {
            const response = await api.get(`/entities/${name || this.name}/${id}`);
            const entity = response.data;
            this.context.commit('setEntity', entity);
            return response.data;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async add({ entity: { options }, name }) {
        try {
            return await api.post(`/entities/${name || this.name}`, {
                params: {
                    options
                }
            });
        } catch (e) {
            console.error(e);
            return {
                data: { message: `Произошла ошибка:\n ${e.response.data}` }
            };
        }
    }

    @Action
    async save({ entity: { id, options }, name }) {
        try {
            return await api.put(`/entities/${name || this.name}`, {
                params: {
                    id,
                    options
                }
            });
        } catch (e) {
            console.error(e);
            return {
                data: { message: `Произошла ошибка:\n ${e.response.data}` }
            };
        }
    }

    @Action
    async delete({ entity: { id }, name }) {
        try {
            return await api.delete(`/entities/${name || this.name}/${id}`);
        } catch (e) {
            console.error(e);
            return {
                data: { message: `Произошла ошибка:\n ${e.response.data}` }
            };
        }
    }
}
