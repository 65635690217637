import { Action, Module, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';
import { UtmUtils } from '@/utils/utm';
const Cookie = process.client ? require('js-cookie') : undefined;

const getCurrentPage = () => {
    return window.location.href;
};

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Form extends VuexModule {
    @Action
    async sendForm(payload) {
        try {
            // @ts-ignore
            if (typeof window.yaCounter17451247 !== 'undefined') {
                // @ts-ignore
                window.yaCounter17451247.reachGoal('lead');
            }
            if (typeof window['dataLayer'] !== 'undefined') {
                // @ts-ignore
                window.dataLayer.push({
                    event: 'event-to-ga',
                    eventCategory: 'conversion',
                    eventAction: 'lead'
                });
            }

            let metrikaId = '';
            if (typeof window['ym'] !== 'undefined') {
                return await window['ym'](17451247, 'getClientID', async result => {
                    return await sendResult(payload, result);
                });
            } else {
                return await sendResult(payload, metrikaId);
            }
            // @ts-ignore
        } catch (e) {
            console.error(e);
        }
    }
}

const sendResult = async (payload, metrikaId) => {
    const utmUtils = new UtmUtils();

    return await api.post('/forms/results', {
        params: {
            fields: payload.fields,
            form: payload.id,
            page: getCurrentPage(),
            metrikaId: metrikaId,
            utm: utmUtils.getUtm(),
            roistat: Cookie.get('roistat_visit')
        }
    });
};
