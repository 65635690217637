import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';
import { Vue } from 'nuxt-property-decorator';

export interface PageOgMeta {
    [key: string]: string;
}

export interface PageMeta {
    title: string;
    description?: string;
    keywords?: string[];
    og?: PageOgMeta;
}

export interface GroupOptions {
    name: string;
    slug: string;
    parent?: string;
    order: number;
    showInMenu: 'true' | 'false';
}

export interface IGroup {
    _id?: string;
    options: GroupOptions;
}

export interface PageOptions {
    notAddToSitemap: boolean;
    slug: string;
    meta: PageMeta;
    palette: PagePalette;
    group?: IGroup;
    showInMenu: boolean;
    buildUrlFromRoot: boolean;
    pageType: 'product' | 'blog';
}

export interface PageComponentOptions {
    [key: string]: any;
}

export interface PageComponent {
    id?: string;
    active?: boolean;
    edit?: boolean;
    name: string;
    fetch?: string;
    style?: object;
    options: PageComponentOptions;
    tempId?: string;
}

export interface PagePalette {
    bg: string;
    icon: string;
    trigger: string;
    text: string;
}

export interface IPage {
    id?: string;
    components: PageComponent[];
    edit?: boolean;
    options: PageOptions;
    tempId?: string;
    lastSave?: string | Date;
    lastPub?: string | Date;
    update?: boolean;
}

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Page extends VuexModule implements IPage {
    components: PageComponent[] = [];
    options: PageOptions = {
        slug: '',
        meta: {
            title: ''
        },
        palette: {
            bg: '',
            icon: '',
            trigger: '',
            text: ''
        },
        notAddToSitemap: true,
        showInMenu: false,
        buildUrlFromRoot: false,
        pageType: 'product'
    };
    id = '';
    currentPage: IPage | null = null;

    @Mutation
    setComponents(val: PageComponent[]) {
        this.components = val;
    }

    @Mutation
    setComponentByIndex(payload) {
        Vue.set(this.components, payload.index, payload.newComponent);
    }

    @Mutation
    setCurrentPage(page) {
        this.currentPage = {
            ...page,
            options: {
                ...page.options,
                pageType: page.options.pageType || 'product'
            }
        };
    }

    @Mutation
    setOptions(val: PageOptions) {
        this.options = val;
    }

    @Mutation
    setId(val) {
        this.id = val;
    }

    @Action
    async fetch(payload) {
        try {
            let response;
            if (payload.subcategory !== undefined && payload.category !== undefined) {
                response = await api.get<IPage>(
                    `/pages/pub/${payload.category}/${payload.subcategory}/${payload.slug}`
                );
            } else if (payload.category !== undefined) {
                response = await api.get<IPage>(
                    `/pages/pub/${payload.category}/${payload.slug}`
                );
            } else {
                response = await api.get<IPage>(`/pages/pub/${payload.slug}`);
            }

            const page = response.data;
            this.context.commit('setCurrentPage', page);
            this.context.commit('setOptions', page.options);
            this.context.commit('setComponents', page.components);
            this.context.commit('setId', page.id);
            return response.data;
        } catch (e) {
            return 'error';
        }
    }
}
