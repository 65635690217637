import { Action, Module, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Collections extends VuexModule {
    @Action
    async get(payload) {
        try {
            let response = await api.get(`${payload.path}`);
            return response.data;
        } catch (e) {
            return 'error';
        }
    }

    @Action
    async post(payload) {
        try {
            let response = await api.post(`${payload.path}`, {
                params: payload.conditions
            });
            return response.data;
        } catch (e) {
            return 'error';
        }
    }
}
