import { Action, Module, Mutation } from 'vuex-module-decorators';
import { IGroup, IPage, PageComponent } from '@/store/page';
import { Vue } from 'nuxt-property-decorator';
import api from '@/services/api';
import { makeid, makeObject, makeOptions } from '@/components/editor/utils/editor';
import componentsOptionsTypes from '@/components/editor/config/options';
import pagesOptionsTypes from '@/components/editor/config/pageOptions';
import Editor from '@/store/editor';

interface IEditor {}

const sanitizeComponent = (component: PageComponent): PageComponent => {
    let newComponent = { ...component };
    let types = componentsOptionsTypes[newComponent.name];
    if (types && types.options) {
        newComponent.options = makeOptions(types.options, newComponent.options);
    }
    return newComponent;
};

const makePage = () => {
    return {
        id: '',
        options: {
            ...makeObject(pagesOptionsTypes.default.options),
            slug: ''
        },
        tempId: makeid(16)
    };
};

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Pages extends Editor implements IEditor {
    pages: IPage[] = [];
    groups = [];

    activeGroup: IGroup | null = null;

    @Mutation
    setPages(pages) {
        this.pages = pages;
    }

    @Mutation
    setPage(payload) {
        let index = this.pages.findIndex(page => page.id === payload.id);
        Vue.set(this.pages[index], 'lastPub', payload.data.lastPub);
    }

    @Mutation
    setGroups(groups) {
        this.groups = groups;
    }

    @Mutation
    setActiveGroup(group: IGroup) {
        Vue.set(this, 'activeGroup', group);
    }

    @Mutation
    addGroup(group: IGroup) {
        Vue.set(this.groups, this.groups.length, group);
    }

    @Mutation
    addPage(newPage) {
        this.pages.push(newPage);
    }

    @Mutation
    removePage(removePage) {
        let index = this.pages.findIndex(page => {
            return (
                (page.id && page.id === removePage.id) ||
                (page.tempId && page.tempId === removePage.tempId)
            );
        });
        Vue.delete(this.pages, index);
    }

    @Mutation
    copyPage(payload) {
        Vue.set(this.pages, payload.newIndex, payload.page);
    }

    @Action
    async addGroupAction(group) {
        try {
            let result = await api.post('/groups', {
                params: {
                    options: group.options
                }
            });
            if (result.data) {
                this.context.commit('addGroup', result.data.group);
            }
            return result;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    copyPageAction(newPage) {
        let page: IPage = JSON.parse(JSON.stringify(newPage));
        let newIndex = this.pages.length;
        delete page.id;
        page.tempId = makeid(16);
        page.options.slug = page.options.slug + '_copy_' + this.pages.length;
        page.options = makeOptions(pagesOptionsTypes.default.options, page.options);
        page.options.notAddToSitemap = true;
        page.components = page.components.map(component => {
            return sanitizeComponent(component);
        });
        page.lastPub = undefined;
        page.lastSave = undefined;

        this.context.commit('copyPage', { newIndex, page });
        return this.context.dispatch('saveOnePage', { page });
    }

    @Action
    async saveOnePage({ page, saveComponents = true }) {
        try {
            if (!page.id) {
                let result = await api.post('/pages', {
                    params: {
                        components: page.components,
                        options: page.options,
                        imgs: page.imgs
                    }
                });

                if (result.data) {
                    this.context.commit('setCurrentPage', result.data.entity);
                }
                return result;
            } else {
                const params = {
                    id: page.id,
                    options: page.options,
                    imgs: page.imgs
                };

                if (saveComponents) {
                    params['components'] = page.components;
                }

                return await api.put('/pages', {
                    params: params
                });
            }
        } catch (e) {
            console.error(e);
            return { data: { message: `Произошла ошибка:\n ${e.message}` } };
        }
    }

    @Action
    async pubPage(id) {
        try {
            this.context.commit('setPage', {
                data: { lastPub: new Date() },
                id
            });
            return await api.post('/pages/pub', {
                params: {
                    id: id
                }
            });
        } catch (e) {
            console.error(e);
            return { data: { message: `Произошла ошибка:\n ${e.message}` } };
        }
    }

    @Action
    async cancelPubPage(id) {
        try {
            this.context.commit('setPage', {
                data: { lastPub: undefined },
                id: id
            });
            return await api.post('/pages/pub/cancel', {
                params: {
                    id: id
                }
            });
        } catch (e) {
            console.error(e);
            return { data: { message: `Произошла ошибка:\n ${e.message}` } };
        }
    }

    @Action
    async deletePage(payload) {
        if (payload.page.id) {
            await api.delete('/pages/' + payload.page.id, {});
        }

        this.context.commit('removePage', payload.page);
    }

    @Action
    async fetchAllPages() {
        try {
            const response = await api.get<IPage>(`/pages`);
            const pages = response.data;
            this.context.commit('setPages', pages);
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async fetchAllGroups(admin) {
        try {
            const response = await api.get<IGroup>(`/groups`, {
                params: {
                    admin
                }
            });
            const groups = response.data;
            ////
            this.context.commit('setGroups', groups);
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async setNewPage() {
        this.context.commit('setCurrentPage', makePage());
    }
}
