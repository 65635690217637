import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';
import { IUser } from '@/store/user';

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Users extends VuexModule {
    users: IUser[] = [];
    user: IUser = {
        active: true,
        id: '',
        _id: '',
        login: '',
        name: '',
        password: '',
        role: 1,
        email: '',
        permissions: []
    };

    @Mutation
    setUsers(users) {
        this.users = users;
    }

    @Mutation
    setUser(user) {
        this.user = user;
    }

    @Mutation
    setUserId(id) {
        this.user.id = id;
    }

    @Action
    async fetchAll() {
        try {
            const response = await api.get<IUser>(`/users`);
            const users = response.data;
            this.context.commit('setUsers', users);
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async fetchOne(id) {
        try {
            const response = await api.get<IUser>(`/users/${id}`);
            const user = response.data;
            this.context.commit('setUser', user);
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async add(user: IUser) {
        try {
            return await api.post('/users', {
                params: {
                    login: user.login,
                    password: user.password,
                    name: user.name,
                    active: user.active,
                    email: user.email,
                    role: user.role
                }
            });
        } catch (e) {
            console.error(e);
            return {
                data: { message: `Произошла ошибка:\n ${e.response.data}` }
            };
        }
    }

    @Action
    async save(user: IUser) {
        try {
            return await api.put('/users', {
                params: {
                    id: user.id,
                    login: user.login,
                    password: user.password,
                    name: user.name,
                    role: user.role,
                    active: user.active,
                    email: user.email
                }
            });
        } catch (e) {
            console.error(e);
            return {
                data: { message: `Произошла ошибка:\n ${e.response.data}` }
            };
        }
    }
}
