import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';
import { makeObject } from '@/components/editor/utils/editor';
import { settings as settingsData } from '@/components/editor/config/settings';
import { Vue } from 'nuxt-property-decorator';

export interface ISettings {
    name: string;
    options: any;
}

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Settings extends VuexModule {
    settings?: ISettings = {
        name: '',
        options: {}
    };

    @Mutation
    setSettings(settings) {
        Vue.set(this, 'settings', settings);
    }

    @Action
    async save(settings) {
        try {
            return await api.put('/settings', {
                params: {
                    name: settings.name,
                    options: settings.options
                }
            });
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async fetch(name) {
        try {
            let settings = await this.context.dispatch('get', name);
            if (!settings) {
                settings = { options: makeObject(settingsData[name].options) };
                settings.name = name;
            }

            this.context.commit('setSettings', settings);
        } catch (e) {
            console.error(e);
            return 'error';
        }
    }

    @Action
    async get(name) {
        try {
            let settings;
            let response = await api.get(`/settings/${name}`);
            if (response.data.options) {
                settings = { options: response.data.options };
                settings.name = name;
            }
            return settings;
        } catch (e) {
            console.error(e);
            return 'error';
        }
    }
}
