import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IGroup } from '@/store/page';
import api from '@/services/api';

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Groups extends VuexModule {
    group: IGroup | null = null;

    @Mutation
    setGroup(group: IGroup) {
        this.group = group;
    }

    @Action
    async save(group) {
        try {
            return await api.put('/groups', {
                params: {
                    id: group.id,
                    options: group.options
                }
            });
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async delete(group) {
        try {
            return await api.delete(`/groups/${group.id}`);
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async fetchOne(id) {
        try {
            const response = await api.get<IGroup>(`/groups/${id}`);
            const group = response.data;
            this.context.commit('setGroup', group);
        } catch (e) {
            console.error(e);
        }
    }
}
