import { Action, Module, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Colors extends VuexModule {
    @Action
    async fetchOne(id) {
        try {
            const response = await api.get(`/colors/${id}`);
            const entity = response.data;
            console.log({ entity });
            return entity;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async fetchAll() {
        try {
            const response = await api.get(`/colors/`);
            const entity = response.data;
            console.log({ entity });
            return entity;
        } catch (e) {
            console.error(e);
        }
    }
}
