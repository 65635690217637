/*
 *
 * @param date :: 'yyyy-mm-dd' -> 'dd mm yyyy'
 *
 * */
export function formatDate(date: string) {
    const MONTHS = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
    ];

    if (date) {
        const [year, month, day] = date.split('-');
        return `${day} ${MONTHS[parseInt(month) - 1]} ${year}`;
    }

    return '';
}
