import { Action, Module, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Form extends VuexModule {
    @Action
    async fetchOne(id) {
        try {
            const response = await api.get(`/menu/${id}`);
            const entity = response.data;
            console.log({ entity });
            return entity;
        } catch (e) {
            console.error(e);
        }
    }
}
