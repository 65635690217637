export interface Utm {
    gclid: string;
    utm_medium: string;
    utm_source: string;
    utm_term: string;
    yclid: string;
    utm_content: string;
}

export class UtmUtils {
    utm: Utm = {
        gclid: '',
        utm_content: '',
        utm_medium: '',
        utm_source: '',
        utm_term: '',
        yclid: ''
    };

    fetchUtmFromQuery(query) {
        console.log({ query });
        for (let key in this.utm) {
            console.log(key);
            this.utm[key] = query[key];
        }
    }

    save() {
        console.log(this.utm);
        if (this.utm.yclid || this.utm.gclid) {
            localStorage.setItem('pinq_utm', JSON.stringify(this.utm));
        }
    }

    getUtm() {
        const utms = localStorage.getItem('pinq_utm');
        if (utms) {
            return JSON.parse(utms);
        }
        return this.utm;
    }
}
