import { Action, Module, Mutation } from 'vuex-module-decorators';
import Page, { IPage, PageComponent } from '@/store/page';
import { Vue } from 'nuxt-property-decorator';
import api from '@/services/api';
import {
    getComponentImages,
    makeid,
    makeOptions,
    resetComponentImages
} from '@/components/editor/utils/editor';
import dotProp from 'dot-prop';
import { vueSet } from 'vue-deepset';
import { getComponentsMap } from '@/components';

interface IEditor {}

const componentsMap = getComponentsMap();

export const sanitizeComponent = (component: PageComponent) => {
    let newComponent = { ...component };
    let componentImgs: object[] = [];

    let types = getComponentMeta(newComponent.name);
    if (types && types.options) {
        newComponent.options = makeOptions(types.options, newComponent.options);
        componentImgs = getComponentImages();
        resetComponentImages();
    }
    return [newComponent, componentImgs];
};

export const getComponentMeta = name => {
    try {
        let comp = require(`@/components/blocks/${componentsMap[name]}${name}.vue`);
        if (comp) {
            return comp.meta;
        }
    } catch (e) {}
};

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Editor extends Page implements IEditor {
    public editedComponents: PageComponent[] = [];
    public componentsList: [] = [] as any;

    @Mutation
    setComponentsList(list) {
        Vue.set(this, 'componentsList', list);
    }

    @Mutation
    addComponent(payload) {
        let newComponent = {
            name: payload.componentName,
            options: payload.options,
            edit: true,
            active: true
        } as PageComponent;
        newComponent.tempId = makeid(16);

        let newArray: PageComponent[] = [];
        if (this.components.length) {
            for (let i in this.components) {
                this.components[i].edit = false;
                newArray.push(this.components[i]);
                if (i === payload.index.toString()) {
                    newArray.push(newComponent);
                }
            }
        } else {
            newArray.push(newComponent);
        }

        //TODO: Почему то не иницилизируется state
        if (!this.editedComponents) {
            Vue.set(this, 'editedComponents', []);
            this.editedComponents = [];
        }
        Vue.set(this.editedComponents, this.editedComponents.length, newComponent);

        this.components = newArray;
    }

    @Mutation
    editComponent(payload) {
        console.log(payload, 'editComponent');
        //TODO: Почему то не иницилизируется state
        if (!this.editedComponents) {
            Vue.set(this, 'editedComponents', []);
            this.editedComponents = [];
        }

        if (payload.inner) {
            payload.component.inner = payload.inner;
        }

        Vue.set(this.editedComponents, this.editedComponents.length, payload.component);
    }

    @Mutation
    deleteComponent(component: PageComponent) {
        if (component !== undefined) {
            // @ts-ignore
            const index = this.components.findIndex(
                i =>
                    (component.id && i.id === component.id) ||
                    (component.tempId && i.tempId === component.tempId)
            );

            Vue.delete(this.components, index);
        }
    }

    @Mutation
    clearEditComponent() {
        if (this.editedComponents) {
            Vue.delete(this.editedComponents, this.editedComponents.length - 1);
        }
    }

    @Mutation
    setComponent(payload) {
        console.log(payload, 'setComponent');
        Vue.set(payload.component, payload.prop, payload.value);
    }

    @Mutation
    setSort({ component, direction }) {
        let currentIndex = this.components.findIndex(
            i =>
                (component.id && i.id === component.id) ||
                (component.tempId && i.tempId === component.tempId)
        );
        let nextComponent = direction === 'down' ? currentIndex + 1 : currentIndex - 1;
        if (nextComponent > -1 && nextComponent !== this.components.length) {
            let components = JSON.parse(JSON.stringify(this.components));
            components.map((comp, index) => {
                if (index === currentIndex) {
                    Vue.set(this.components, index, components[nextComponent]);
                }
                if (index === nextComponent) {
                    Vue.set(this.components, index, components[currentIndex]);
                }
                return comp;
            });
        }
    }

    @Mutation
    setSortOption({ option, currentIndex, direction }) {
        const nextIndex = direction === 'down' ? currentIndex + 1 : currentIndex - 1;

        if (nextIndex > -1 && nextIndex !== option.length) {
            const optionCopy = option.slice();

            optionCopy.forEach((opt, index) => {
                if (index === currentIndex) {
                    Vue.set(option, index, optionCopy[nextIndex]);
                }
                if (index === nextIndex) {
                    Vue.set(option, index, optionCopy[currentIndex]);
                }
                return opt;
            });
        }
    }

    @Mutation
    setOptionValue(payload) {
        console.log(payload, 'setOptionValue');
        //payload.entity - ссылка на объект state (компонент, страницу, или группу)
        let entityOptions = payload.entity.options;
        if (!entityOptions) {
            entityOptions = payload.entity;
        }
        let props = payload.key.split('.');
        let last = props.pop();
        if (last.match(/\d+/)) {
            let arr = dotProp.get(entityOptions, props.join('.'), []);
            Vue.set(arr, last, payload.value);
        } else {
            // const currentIndex = this.components.findIndex(
            //     i => payload.entity.id && i.id === payload.entity.id
            // );
            vueSet(entityOptions, payload.key, payload.value);
            //Vue.set(this.components, currentIndex, payload.entity);
        }
    }

    @Mutation
    setEntityOptions(payload) {
        console.log(payload, 'setEntityOptions');
        Vue.set(payload.entity, 'options', payload.options);
        Vue.set(payload.entity, 'preset', payload.preset);
    }

    @Mutation
    addOptionValue(payload) {
        if (!Array.isArray(dotProp.get(payload.entity.options, payload.key))) {
            vueSet(payload.entity.options, payload.key, []);
        }
        let option: any = dotProp.get(payload.entity.options, payload.key, []);
        if (option !== undefined && payload.value !== undefined) {
            option.push(payload.value);
        }
    }

    @Mutation
    deleteOptionValue(payload) {
        let option: any = dotProp.get(payload.entity.options, payload.key, []);
        if (option && payload.index !== undefined) {
            option.splice(payload.index, 1);
        }
    }

    @Action
    async savePage() {
        try {
            const components: object[] = [];
            let imgs: object[] = [];

            this.components.forEach(component => {
                const [sanatizedComponent, componentImgs] = sanitizeComponent(component);
                components.push(sanatizedComponent);
                // @ts-ignore
                imgs = [...imgs, ...componentImgs];
            });

            return await api.put('/pages', {
                params: {
                    id: this.id,
                    components,
                    imgs,
                    options: this.options
                }
            });
        } catch (e) {
            console.error(e);
            return { data: { message: `Произошла ошибка:\n ${e.message}` } };
        }
    }

    @Action
    async loadFile(payload) {
        try {
            const response = await api.post('/files', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    size: payload.image.size
                }
            });
            return response.data;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async loadRawFile(payload) {
        try {
            const response = await api.post('/files/raw', payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    size: payload.file.size
                }
            });
            return response.data;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async resizeFile(payload) {
        try {
            const responce = await api.post('/files/resize', {
                image: payload.image
            });
            return responce.data;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async fetchAll(payload) {
        try {
            let response;

            if (payload.category !== undefined) {
                response = await api.get<IPage>(
                    `/pages/${payload.category}/${payload.slug}`
                );
            } else {
                response = await api.get<IPage>(`/pages/${payload.slug}`);
            }

            const page = response.data;

            this.context.commit('setCurrentPage', page);
            this.context.commit('setOptions', page.options);
            this.context.commit('setComponents', page.components);
            this.context.commit('setId', page.id);
        } catch (e) {
            console.error(e);
        }
    }
}
