import axios from 'axios';
import { getApiUrl } from '@/utils/apiUrl';
import * as https from 'https';

let url = `${getApiUrl()}/api`;

const axiosInstance = axios.create({
    baseURL: url,
    withCredentials: true,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    }),
    maxRedirects: 0,
    timeout: 10000
});

export default axiosInstance;
