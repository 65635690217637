import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';
// @ts-ignore
const cookieparser = process.server ? require('cookieparser') : undefined;
// @ts-ignore
const Cookie = process.client ? require('js-cookie') : undefined;

export interface IUser {
    active: boolean;
    email?: string;
    name: string;
    id: string;
    _id: string;
    login: string;
    password: string;
    permissions: string[];
    role: number;
}

@Module({
    namespaced: true,
    stateFactory: true
})
export default class User extends VuexModule {
    user: IUser;
    auth: IUser | null = null;

    @Mutation
    setUser(user) {
        this.user = user;
    }

    @Mutation
    setAuth(user) {
        this.auth = user;
    }

    get canDelete() {
        if (!this.auth) {
            return false;
        }

        return this.auth.permissions.includes('admin');
    }

    get isAdmin() {
        if (!this.auth) {
            return false;
        }

        return this.auth.permissions.includes('admin');
    }

    get canPublish() {
        if (!this.auth) {
            return false;
        }

        return (
            this.auth.permissions.includes('admin') ||
            this.auth.permissions.includes('pages:publish')
        );
    }

    get canCreateGroups() {
        if (!this.auth) {
            return false;
        }

        return (
            this.auth.permissions.includes('admin') ||
            this.auth.permissions.includes('groups:write')
        );
    }

    get canSettings() {
        if (!this.auth) {
            return false;
        }

        return (
            this.auth.permissions.includes('admin') ||
            this.auth.permissions.includes('pages:settings')
        );
    }

    get canEditPages() {
        if (!this.auth) {
            return false;
        }

        return (
            this.auth.permissions.includes('admin') ||
            this.auth.permissions.includes('pages:write') ||
            this.auth.permissions.includes('pages:write-own')
        );
    }

    get canEditOwnPages() {
        if (!this.auth) {
            return false;
        }

        return this.auth.permissions.includes('pages:write-own');
    }

    @Action
    async login(payload) {
        try {
            const response = await api.post('/user/login', payload);
            this.context.commit('setAuth', response.data.user);
            return response.data.user;
        } catch (e) {
            return {
                data: { message: `Произошла ошибка:\n ${e.response.data}` }
            };
        }
    }

    @Action
    async authAction(req) {
        if (req.headers.cookie) {
            const parsed = cookieparser.parse(req.headers.cookie);

            try {
                api.defaults.headers['Authorization'] = `Bearer ${parsed.token}`;

                const response = await api.get('/user');

                if (response.data) {
                    this.context.commit('setAuth', response.data);
                }
            } catch (e) {
                console.error(e);
                //return {data: {'message': `Произошла ошибка:\n ${e.response.data}`}};
            }
        }
    }

    @Action
    async logout() {
        try {
            this.context.commit('setAuth', null);
            await api.get('/user/logout');
        } catch (e) {
            console.error(e);
            //return {data: {'message': `Произошла ошибка:\n ${e.response.data}`}};
        }
    }

    @Action
    async install() {
        try {
            let response = await api.get('/install');
            return response.data.user;
        } catch (e) {
            return {
                data: { message: `Произошла ошибка:\n ${e.response.data}` }
            };
        }
    }

    // @Action
    // register({dispatch, commit, state}, payload) {
    //
    //     dispatch('addUser', payload);
    // }
}
