import { Action, Module, VuexModule } from 'vuex-module-decorators';
import api from '@/services/api';

@Module({
    namespaced: true,
    stateFactory: true
})
export default class Modal extends VuexModule {
    @Action
    async fetchModal(id) {
        try {
            const response = await api.get(`/modals/${id}`);
            return response.data;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async fetchAll() {
        try {
            const response = await api.get(`/modals`);
            return response.data;
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async delete(modal) {
        try {
            return await api.delete(`/products/${modal.id}`);
        } catch (e) {
            console.error(e);
        }
    }
}
