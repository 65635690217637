export const strict = false;
// @ts-ignore
const cookieparser = process.server ? require('cookieparser') : undefined;

export const actions = {
    async nuxtServerInit({ dispatch }, { req }) {
        if (req.headers.cookie) {
            const parsed = cookieparser.parse(req.headers.cookie);
            if (parsed.token) {
                await dispatch('user/authAction', req);
            }
        }
    },

    async loadPageStore({ commit }, payload) {
        commit('setPageStore', payload);
    }
};

export const state = () => ({
    pageStore: 'page'
});

export const mutations = {
    setPageStore(state, store) {
        state.pageStore = store;
    }
};
